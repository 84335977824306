/** allow errors from growfin.ai, account-receivables domain also
 * matches -> "https://growfin.ai", "https://account-receivables.com"
 * matches -> "https://assets.growfin.ai", "https://assets.account-receivables.com"
 * matches -> "https://(subdomain).growfin.ai", "https://(subdomain).account-receivables.com"
 * matches -> "http://localhost.ar-dev.com"
 */
export const allowedUrls = () => [
  /https:\/\/(.*\.)?growfin\.ai/,
  /https:\/\/(.*\.)?account-receivables\.com/,
  /http:\/\/localhost\.ar-dev\.com/,
];

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/#decluttering-sentry
export const deniedUrls = () => [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/#decluttering-sentry
export const ignoredErrors = () => [
  // See: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  'ResizeObserver loop limit exceeded',
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  'Unauthorized',
  'Request failed with status code 401',
  '401',
  'Request failed with status code 504',
  'Object captured as exception',
];
