import { Row } from 'antd';
import { DashboardPostFilterParams } from 'pages/Dashboard/types';
import { useTotalBalanceTileQuery, useTotalOutstandingQuery } from 'queries/admin-dashboard';
import Others from './Cards/Others';
import { PrimaryColumn } from './Cards/PrimaryCards';
import SecondaryCards from './Cards/SecondaryCards';
import TerritoryCards from './Cards/TerritoryCards';

interface TotalCollectionBalanceOutstandingProps {
  snapshotFilterParams: DashboardPostFilterParams;
}

export default function TotalCollectionBalanceOutstanding(
  props: TotalCollectionBalanceOutstandingProps
) {
  const { data: totalBalance, isLoading: totalBalanceLoading } = useTotalBalanceTileQuery(
    props.snapshotFilterParams
  );
  const { data: totalOutstandingAmount, isLoading: totalOutstandingLoading } =
    useTotalOutstandingQuery(props.snapshotFilterParams);

  return (
    <Row gutter={[16, 16]} wrap>
      <PrimaryColumn
        totalBalanceLoading={totalBalanceLoading}
        totalOutstandingLoading={totalOutstandingLoading}
        totalBalance={totalBalance}
        totalOutstanding={totalOutstandingAmount}
        snapshotFilterParams={props.snapshotFilterParams}
      />
      <SecondaryCards
        totalBalanceLoading={totalBalanceLoading}
        totalBalance={totalBalance}
        snapshotFilterParams={props.snapshotFilterParams}
      />
      <TerritoryCards
        totalBalanceLoading={totalBalanceLoading}
        totalOutstandingLoading={totalOutstandingLoading}
        totalBalance={totalBalance}
        totalOutstanding={totalOutstandingAmount}
        snapshotFilterParams={props.snapshotFilterParams}
      />
      <Others snapshotFilterParams={props.snapshotFilterParams} />
    </Row>
  );
}
