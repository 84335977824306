import CurrentDueTile from 'components/Dashboard/CurrentDueTile';
import DSOTile from 'components/Dashboard/DSOTile';
import ReceivableOutstanding from 'components/Dashboard/ReceivablesOutstanding/ReceivableOutstanding';
import { Tile } from '../TileTitle';
import { DashBoardCardProps } from './type';

interface SecondaryCardsProps
  extends Omit<DashBoardCardProps, 'totalOutstandingLoading' | 'totalOutstanding'> {}

export default function SecondaryCards(props: SecondaryCardsProps) {

  return (
    <>
      <Tile>
        <CurrentDueTile loading={props.totalBalanceLoading} totalOutstanding={props.totalBalance} />
      </Tile>
      <Tile>
        <ReceivableOutstanding snapshotFilterParams={props.snapshotFilterParams} />
      </Tile>
      <Tile>
        <DSOTile filterParams={props.snapshotFilterParams} />
      </Tile>
    </>
  );
}
