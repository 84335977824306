import { Descriptions, Divider } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { CommentsBlock } from 'components/CollectionActivities/Comment/CommentsBlock';
import { ActionButtons } from 'components/CollectionActivities/Common/Details/ActionButons/ActionButtons';
import {
  AttachmentsBlock,
  DateBlock,
  InvoicesBlock,
  LabelBlock,
  TextBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { SubscriptionType } from 'components/CollectionActivities/Common/SubscriptionType';
import useActivityBackButton from 'components/CollectionActivities/hooks/useActivityBackButton';
import useGetActivityLink from 'components/CollectionActivities/hooks/useGetActivityLink';
import { ActivityContent, ActivityTitle } from 'components/CollectionActivities/style';
import { getActivityUnitItems } from 'components/CollectionActivities/utils';
import { OPEN_TYPE, useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';

import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';
import { ActivityDetailsBase, ActivityType } from 'types/activities/activity-types';
import { CallLog } from 'types/activities/callLog';

interface CallLogDetailsProps extends ActivityDetailsBase {
  data?: CallLog;
  isLoading: boolean;
}

const t = {
  deleteTitle: 'Delete Call Log',
  deleteDescription: 'Are you sure you want to Delete the Call Log entry?',
};

function CallLogDetailsBody(props: CallLogDetailsProps) {
  const {
    hideActions = false,
    data,
    isLoading,
    subscriptionType,
    onDelete,
    editLoading,
    deleteLoading,
    customer,
    activitiesMode,
  } = props;
  const { cardLabel, Icon } = getActivityUnitItems(ActivityType.CALL_LOG);
  const { entitySearchParams } = useEntitySearchParams();
  const ActivityCopyLink = useGetActivityLink(activitiesMode);
  const ActivityBackButton = useActivityBackButton();
  const Title = (
    <ActivityTitle align="center" gap="var(--space-8)">
      <ActivityBackButton />
      <ActivityIconButton
        icon={Icon}
        size="small"
        css={{ '--activityBg': 'var(--blue-1)' }}
        viewMode
        loading={isLoading}
        hideBackground
      />
      <GrowText>{cardLabel}</GrowText>
      <ActivityCopyLink data={data} customer={customer} type={ActivityType.CALL_LOG} />
    </ActivityTitle>
  );

  const Header = (
    <Flex direction="column" gap="var(--space-8)">
      <Flex justify="space-between">
        {Title}
        <ActionButtons
          hide={hideActions}
          onDelete={onDelete}
          id={data?.id}
          editLoading={editLoading}
          deleteLoading={deleteLoading}
          title={t.deleteTitle}
          description={t.deleteDescription}
          type={ActivityType.CALL_LOG}
        />
      </Flex>
    </Flex>
  );

  const InvoiceArea =
    !isLoading && data ? <InvoicesBlock invoices={data.invoices} isBaseCurrency /> : null;

  const Date =
    !isLoading && data ? <DateBlock dateString={data.call_date} strong dateOnly /> : null;
  const Note =
    !isLoading && data ? <TextBlock value={data.description} size={'var(--fs-16)'} /> : null;
  const Attachments =
    !isLoading && data?.attachments?.length ? (
      <ActivityContent layout="vertical" colon={false}>
        <Descriptions.Item label={<LabelBlock label="Attachments" />}>
          <AttachmentsBlock attachments={data.attachments ?? []} hideTitle />
        </Descriptions.Item>
      </ActivityContent>
    ) : null;

  const Sub = !isLoading && data ? <SubscriptionType subscriptionType={subscriptionType} /> : null;
  const Content = (
    <Flex gap="var(--space-12)" direction="column">
      <Flex direction="column" gap="var(--space-8)">
        {Header}
        {Sub}
      </Flex>
      <Flex gap="var(--space-16)" direction="column">
        <ActivityContent layout="vertical" colon={false}>
          <Descriptions.Item>{Note}</Descriptions.Item>
        </ActivityContent>
        {Attachments}
        <HideWrapper hide={!data?.invoices}>
          <ActivityContent layout="vertical" colon={false}>
            <Descriptions.Item label={<LabelBlock label="Invoices" />}>
              {InvoiceArea}
            </Descriptions.Item>
          </ActivityContent>
        </HideWrapper>
        <ActivityContent layout="vertical" colon={false}>
          <Descriptions.Item label={<LabelBlock label="Call Date" />}>{Date}</Descriptions.Item>
        </ActivityContent>
      </Flex>
    </Flex>
  );

  const Footer =
    data && !isLoading ? (
      <CommentsBlock
        customerId={props.customer?.id}
        activityId={data.id}
        activityType={ActivityType.CALL_LOG}
        comments={data.comments}
        isOpen={entitySearchParams[EActivityQueryParams.OPEN] === OPEN_TYPE.COMMENTS}
      />
    ) : null;
  return (
    <>
      {Content}
      <Divider style={{ margin: 'var(--space-24) 0', borderColor: 'var(--gray-5)' }} />
      {Footer}
    </>
  );
}

export { CallLogDetailsBody };
