import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { dashboardTexts } from 'components/Dashboard/texts';
import TotalCustomersInvoices from 'components/Dashboard/TotalCustomersInvoices';
import { PageLink } from 'lib/router';
import DashboardCardTemplate from 'pages/Dashboard/AdminDashboard/SnapShot/CollectionBalanceAndOutstanding/CardTemplate';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import React from 'react';
import { TotalOutstandingResponse } from 'types/api/admin-dashboard';

interface Props extends DashboardComponentProps {
  totalOutstanding?: TotalOutstandingResponse;
  loading: boolean;
}

function UnappliedPayments(props: Props) {
  const { totalOutstanding, loading } = props;

  const unAppliedPayment = totalOutstanding?.total_outstanding.un_applied_payment_amount;
  const unAppliedPaymentCustomersCount = unAppliedPayment?.customer_count;

  const InputLoaderJSX = <InputLoader active={loading} />;

  const UnappliedPaymentAmount = unAppliedPayment && (
    <PageLink
      appPage="PAYMENT_LIST"
      searchParams={`filter=ALL`}
      state={{ addPrimaryPathAsChildPath: true }}
    >
      <AmountWithCurrencySplit
        type="multi"
        amountWithCurrencySplit={unAppliedPayment.amount}
        className="text-20 semi-bold"
        formatOptions={{ maximumFractionDigits: 0 }}
      />
    </PageLink>
  );

  const TotalCustInvoiceInfo = (
    <TotalCustomersInvoices
      loading={loading}
      customersCount={unAppliedPaymentCustomersCount}
      showInvoice={false}
    />
  );

  const AmountInfo = <> {loading ? InputLoaderJSX : UnappliedPaymentAmount}</>;
  return (
    <DashboardCardTemplate
      title={dashboardTexts.unappliedPayments.title}
      amountInfo={AmountInfo}
      customerInvoiceCount={TotalCustInvoiceInfo}
    />
  );
}

export default React.memo(UnappliedPayments);
