import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { InfoPopover } from 'components/Common/UtilityPopover';
import { PageLink } from 'lib/router';
import DashboardCardTemplate from 'pages/Dashboard/AdminDashboard/SnapShot/CollectionBalanceAndOutstanding/CardTemplate';
import { DashboardPostFilterParams } from 'pages/Dashboard/types';
import { useTotalReceivablesQuery } from 'queries/admin-dashboard';
import React from 'react';
import { TotalBalance } from '../TotalBalanceTile/style';
import TotatalCustomersInvoices from '../TotalCustomersInvoices';
import { dashboardTexts } from '../texts';

const t = {
  extraContent: 'Total balance of all customers with unpaid invoices',
};

// const keysToOmit = ['participant_scope', 'view_child', 'view_scope'];
const componentTexts = dashboardTexts.receivables;

function ExtraInfo() {
  const Content = (
    <div className="extra-info">
      <GrowText>{t.extraContent}</GrowText>
    </div>
  );

  return (
    <div style={{ cursor: 'pointer' }}>
      <InfoPopover content={Content} />
    </div>
  );
}

interface TotalBalanceTileProps {
  snapshotFilterParams: DashboardPostFilterParams;
}

function ReceivableOutstanding(props: TotalBalanceTileProps) {
  const { data: totalReceivablesAmount, isLoading: totalReceivablesLoading } =
    useTotalReceivablesQuery(props.snapshotFilterParams);
  // const urlSearchParams = new URLSearchParams(); commenting for future use
  const customersCount = totalReceivablesAmount?.customer_count;
  const invoicesCount = totalReceivablesAmount?.invoice_count;

  const InputLoaderJSX = <InputLoader active={totalReceivablesLoading} />;
  //commenting for future use
  // const searchParams = objectToSearchParams(props.snapshotFilterParams);

  // function objectToSearchParams(obj: DashboardPostFilterParams) {
  //   const cleanedObj = omit(obj, keysToOmit);

  //   for (const [key, value] of Object.entries(cleanedObj)) {
  //     if (isArray(value) && value.length > 0) {
  //       urlSearchParams.append(key, JSON.stringify(value));
  //     }
  //   }

  //   return urlSearchParams.toString();
  // }
  const TotalInvoiceBalance = totalReceivablesAmount?.amount ? (
    <PageLink
      appPage="AR_AGING"
      searchParams={`sort_col=TOTAL_DUE_AMOUNT&sort_by=DESC`}
      state={{ addPrimaryPathAsChildPath: true }}
    >
      <AmountWithCurrencySplit
        type="multi"
        amountWithCurrencySplit={totalReceivablesAmount?.amount}
        className="text-24 semi-bold"
        context={componentTexts.title}
        formatOptions={{ maximumFractionDigits: 0 }}
      />
    </PageLink>
  ) : (
    <>-</>
  );

  const TotalBalanceInformation = (
    <TotalBalance>
      <Flex className="value amount" align="center" gap="var(--space-4)">
        {totalReceivablesLoading && !totalReceivablesAmount ? InputLoaderJSX : TotalInvoiceBalance}
      </Flex>
    </TotalBalance>
  );

  const TotalCustInvoiceInfo = (
    <TotatalCustomersInvoices
      loading={totalReceivablesLoading}
      customersCount={customersCount}
      invoicesCount={invoicesCount}
    />
  );

  return (
    <DashboardCardTemplate
      title={componentTexts.title}
      extra={<ExtraInfo />}
      amountInfo={TotalBalanceInformation}
      customerInvoiceCount={TotalCustInvoiceInfo}
    />
  );
}

export default React.memo(ReceivableOutstanding);
