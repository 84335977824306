import TotalBalanceTile from 'components/Dashboard/TotalBalanceTile';
import TotalCollectedTile from 'components/Dashboard/TotalCollectedTile';
import TotalOutstandingTile from 'components/Dashboard/TotalOutstandingTile';
import { Tile } from '../TileTitle';
import { DashBoardCardProps } from './type';

export function PrimaryColumn(props: DashBoardCardProps) {
  return (
    <>
      <Tile>
        <TotalBalanceTile
          totalOutstanding={props.totalBalance}
          loading={props.totalBalanceLoading}
        />
      </Tile>
      <Tile>
        <TotalOutstandingTile
          totalOutstanding={props.totalOutstanding}
          loading={props.totalOutstandingLoading}
        />
      </Tile>
      <Tile>
        <TotalCollectedTile filterParams={props.snapshotFilterParams} />
      </Tile>
    </>
  );
}
