import OverdueTile from 'components/Dashboard/OverdueTile';
import UnappliedCredits from 'components/Dashboard/TotalOutstandingTile/UnappliedCredits';
import UnappliedPayments from 'components/Dashboard/TotalOutstandingTile/UnappliedPayments';
import { Tile } from '../TileTitle';
import { DashBoardCardProps } from './type';

export default function TerritoryCards(props: DashBoardCardProps) {
  return (
    <>
      <Tile>
        <OverdueTile loading={props.totalBalanceLoading} totalOutstanding={props.totalBalance} />
      </Tile>
      <Tile>
        <UnappliedPayments
          filterParams={props.snapshotFilterParams}
          loading={props.totalOutstandingLoading}
          totalOutstanding={props.totalOutstanding}
        />
      </Tile>
      <Tile>
        <UnappliedCredits
          filterParams={props.snapshotFilterParams}
          loading={props.totalOutstandingLoading}
          totalOutstanding={props.totalOutstanding}
        />
      </Tile>
    </>
  );
}
